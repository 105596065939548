import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="md:w-10/12">
                <h2>Baby Formula</h2>
                <p>
                    Legal Action Against Formula Manufacturers for NEC Risks in
                    Premature Babies Formula manufacturers like Enfamil* and
                    Similac* offer high-calorie cow's milk-based products for
                    premature infants. Surprisingly, these products lack
                    warnings about the heightened risk of Necrotizing
                    Enterocolitis (NEC) in preemies. Many hospitals and NICUs
                    use them without caution, increasing the chances of NEC.
                    Parents of affected premature infants are now pursuing legal
                    action against these manufacturers for failing to provide
                    proper warnings. We also represent parents of infants who
                    underwent surgery due to NEC, facing long-term consequences.
                </p>
            </div>
            <div className="relative md:ml-10 w-[210px] h-[271px] mb-10 md:mb-0">
                <Image
                    src="/bestbabyformulalawyers/assets/images/baby-formula.png"
                    alt="baby-formula"
                    layout="fill"
                />
            </div>
        </div>
    );
}
